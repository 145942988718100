<template>
  <div class="route-main">
    <h1>Page Not Found</h1>
  </div>
</template>



<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>

